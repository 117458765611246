import React, { useState} from "react";
import Configs from "../../Assets/editable-stuff/configurations.json";
import { Chat, Phone, Email } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const AboutMe = () => {
  const [heading] = useState("About Us");
  const [aboutDev] = useState(Configs.aboutDev);

  return (
    <div id="aboutme" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
      <div className="container container-fluid p-5">
        <div className="row">
           {   
           <div className = "noImageCss">
            <div className="display-4 mb-5 text-center d-lg-block align-self-center">
              <img
                className="rounded-circle imageLeft"
                src={require('../../Assets/img/Main Page/aboutLogo.png')} 
                alt="profilepicture"
              />
              </div>
            </div>
           }
           <img
            className="rounded-circle imageCss"
            src={require("../../Assets/img/Main Page/aboutLogo.png")} //scale: 78, hexCode: #173962
            alt="..."
          />
          <div className={`col-lg`}>
            <h1 className="display-4 mb-3 text-center">{heading}</h1>
            <p className="lead text-center">{aboutDev}
            <br/>
            <br/>
            <p className="lead text-center"> Call, Text or Email us today!</p>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
              href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.aboutTemplate}>Text</Button>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
              href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.aboutSubject + "&body=" + Configs.aboutTemplate}>Email</Button>
           </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
