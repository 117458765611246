import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {StepLabel, Step, Stepper } from '@material-ui/core';
import clsx from 'clsx';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    backgroundImage: require('../../Assets/img/Automation Page/gmail.png'),
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img style = {{marginRight:"20px"}} src= {require('../../Assets/img/Automation Page/gmail.png')} alt="..."/>,
    2: <img style = {{marginRight:"25px"}} src= {require('../../Assets/img/Automation Page/dropbox.png')} alt="..."/>,
    3: <img style = {{marginRight:"25px"}} src= {require('../../Assets/img/Automation Page/slack.png')} alt="..."/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [{t:'TRIGGER',o:'When I get a new email in Gmail'}, {t:'ACTION',o:'Copy the attachment from Gmail to Dropbox'}, {t:'ACTION',o:'Alert me in Slack about the new Dropbox file'}];
}

export default function ConnectingApps() {
  const classes = useStyles();
  const steps = getSteps();
  
  return (
    <div className={classes.root}> 
      <div id="connectingApps" className="jumbotron jumbotron-fluid bg-white min-vh-auto m-0" style={{paddingTop:"80px"}}>
        <div className="container container-fluid p-5">
          <h1 className="display-4 mb-4 text-center" style={{fontSize: "2.8rem"}}><b>Our Geeks connect the apps you use every day</b></h1>
          <div className="row" style={{backgroundColor: "transparent"}}>
            <div className={`col-lg`}>
              <Stepper  orientation="vertical">
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} style={{backgroundImage:ColorlibStepIcon}}>{label.t}</StepLabel>
                    <p style={{paddingLeft: "55px"}}>{label.o}</p>
                  </Step>
                ))}
              </Stepper>
            </div>
            
            <div className={`col-lg`} style = {{marginTop:"40px"}}>
            <p className="lead" style={{margin: 0}}><b>Start workflows from any app</b></p>
            <p className="lead">Pick a trigger to set off your automation</p>
            <br/>
            <p className="lead" style={{margin: 0}}><b>Finish routine tasks automatically</b></p>
            <p className="lead">Our Geeks complete actions, while you solve more important problems.</p>
            <br/>
            <p className="lead" style={{margin: 0}}><b>Simple, fill-in-the-blank setup</b></p>
            <p className="lead">Connect apps to be automated without any busywork</p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}
