import React from "react";
import { Chat, Phone, Email } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Configs from "../../Assets/editable-stuff/configurations.json";


const WIFIOPTIMIZATION = () => {
 
  return (
    <div id="WIFIOPTIMIZATION" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px", background:"white"}}>
      <div className="container container-fluid p-5">
        <div className="row" style={{marginBottom:"-50px"}}>
          <img
            className="border-secondary rounded-circle imageCss"
            src={require("../../Assets/img/IT Page/wifiLogo.png")} //scale: 78, hexCode: #173962
            alt="..."
          />
          <div className={`col-lg`}>
            <h1 className="display-4 mb-3 text-center" style={{fontSize: "2.8rem"}}>{'WiFi Optimization'}</h1>
            <p className="lead text-center">{'Slow Internet? Cant get a page to load? Keep lagging in your video games? We’ll make your wireless internet better than ever! Protecting you from outsiders, setting parental controls or simply just optimizing your WiFi to its fullest potential.'}
            <br/>
            <br/>
            <p className="lead text-center"> Call, Text or Email us today!</p>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
                href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.wifiTemplate}>Text</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
                href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.wifiSubject + "&body=" + Configs.wifiTemplate}>Email</Button>
            </p>
          </div>
          {
            <div className = "noImageCss">
              <div className="display-4 mb-5 text-center d-lg-block align-self-center">
                <img
                  className="border-secondary rounded-circle"
                  src={require("../../Assets/img/IT Page/wifiLogo.png")}
                  alt="..."
                  style={{maxWidth: "256px", maxHeight: "256px"}}
                />
               </div>
            </div>
          }
        </div>  
      </div>
    </div>
  );
};

export default WIFIOPTIMIZATION;
