import React from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll, scroller} from 'react-scroll';
/* eslint-disable */

export default function PageNavigation() {
  const scrollFunc=(e)=>{
    if(e==='home'){
      scroll.scrollToTop()
    }
    else {
      scroller.scrollTo(e, {
        duration: 1400,
        delay: 0,
        offset: 25,
        smooth: 'easeInOutQuart'
      })
    }
    
  }

  return (
    <div>
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark" style={{backgroundColor: "#d1eef8", padding:0, marginRight:0}} id="mainNavbar">
     
        <h4 className="navbar-brand" id="navMain-1" style={{cursor:"pointer"}} data-toggle="collapse" data-target=".navbar-collapse.show" >
        <Link role="button" to="/">
          <img src={require('../../Assets/img/Navigation/nav_icon.png')} width="50" height="40" className="d-inline-block align-top" alt="" style={{marginRight: "10px", marginLeft: "10px"}}/>
          
          <img src={require('../../Assets/img/Navigation/Logo_text.png')} className="d-inline-block align-top align-right" alt="" style={{marginTop: "7px"}}/>
          </Link>
        </h4>
       
        <button style={{border:"0",padding:0}}className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item" id="navMain-2">
            </li>
            <li className="nav-item" id="navMain-4">
            </li>
          </ul>
          <ul className="form-inline my-2 my-lg-0 " data-toggle="collapse" data-target=".navbar-collapse.show" >
         
            <Link className="btn btn-outline-navi"  role="button" to="/IT">Information Technology</Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-outline-navi" role="button" to="/Web-App">Websites & Apps</Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-outline-navi" role="button" to="/Automation">Automation</Link>
            &nbsp;&nbsp;&nbsp;
            
          </ul>
        </div>
        		
      </nav>
    </div>
  );
  
}