import React from "react";
// import Typist from "react-typist";
import "../../App";

const TermsHeader = () => {
  return (
    <div>
      <div
        id="termsHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
        style={{marginBottom:0}}
     >
        <div className="container container-fluid text-center ">
          <h1 className="header-text-2 footer-padding-top">Terms and Conditions</h1>
          {/* <Typist className = "lead" cursor={{ hideWhenDone: true }}>
            <span className="lead"></span>
          </Typist> */}
        </div>
      </div>
    </div>
  );
};

export default TermsHeader;

