import React from "react";
import { Chat, Phone, Email } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Configs from "../../Assets/editable-stuff/configurations.json";

const VirusRemoval = () => {
  return (
    <div id="VirusRemoval" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
      <div className="container container-fluid p-5">
        <div className="row">
          {
            <div className = "noImageCss">
              <div className="display-4 mb-5 text-center d-lg-block align-self-center">
                <img
                  className="border-secondary rounded-circle"
                  src={require("../../Assets/img/IT Page/virusLogo.png")}
                  alt="..."
                  style={{maxWidth: "256px", maxHeight: "256px"}}
                />
               </div>
            </div>
          }
          <img
            className="border-secondary rounded-circle imageCss"
            src={require("../../Assets/img/IT Page/virusLogo.png")} //scale: 78, hexCode: #173962
            alt="..." 
          />
          <div className={`col-lg`} style={{marginBottom:"-50px"}}>
            <h1 className="display-4 mb-3 text-center">{'Virus Removal'}</h1>
            <p className="lead text-center">{'Malicious software and viruses are impacting your computers performance. Protecting the integrity of your information is more important than ever. Our Geeks will remove any  malware or virus affecting your system either in person or over remote desktop.'}
            <br/>
            <br/>
            <p className="lead text-center"> Call, Text or Email us today!</p>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
                href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.virusTemplate}>Text</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
                href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.virusSubject + "&body=" + Configs.virusTemplate}>Email</Button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirusRemoval;
