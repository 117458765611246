import React, { useState} from "react";
import Configs from "../../Assets/editable-stuff/configurations.json";
import { Button } from "@material-ui/core";
import { Chat, Phone, Email } from "@material-ui/icons";

const AboutAutomation = () => {
  const [heading] = useState("Automation");
  const [aboutAutomation] = useState(Configs.aboutAutomation);

  return (
    <div id="aboutAutomation" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
      <div className="container container-fluid p-5">
        <div className="row">
          <div className={`col-lg text-center`}>
            <img
              className=""
              src={require('../../Assets/img/Automation Page/robot.png')} 
              alt="profilepicture"
            />
        
            <h1 className="display-4 mb-4 text-center">{heading}</h1>
            <p className="lead text-center">{aboutAutomation}</p>
            <br/>
            <p className="lead text-center"> Call, Text or Email us today!</p>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
              href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.automationTemplate}>Text</Button>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
              href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.automationSubject + "&body=" + Configs.automationTemplate}>Email</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAutomation;
