import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

class Contact extends Component {
  render () {
      return (
        <li className="ull"> 
          <img className="imgIcon" src={this.props.image} alt="img"/>   
          <span className ="spanController" style={{marginTop:"15px"}}>{this.props.name}</span>
          <span className="phone">{this.props.phone}</span>
        </li>     
      );
    };
  };

  var APPS = [
    {
      id: 1,
      name: 'Google Sheets',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/sheets.png")
    },
    {
      id: 2,
      name: 'Google Docs',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/googleDocs.png")
      
    },
    {
      id: 3,
      name: 'Outlook',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/outlook.png")
    },
    {
      id: 4,
      name: 'Slack',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/slack.png")
    },
    {
      id: 5,
      name: 'Amazon',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/amazon.png")
    },
    {
      id: 6,
      name: 'eBay',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/ebay.png")
    },
    {
      id: 7,
      name: 'Microsoft Excel',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/excel.png")
    },
      {
      id: 8,
      name: 'Google Calender',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/googleCalender.png")
    },
    {
    id: 9,
    name: 'Google Form',
    phoneNumber: '',
    image: require("../../Assets/img/Automation Page/googleForms.png")
      },
      {
      id: 10,
      name: 'Google Slides',
      phoneNumber: '',
      image: require("../../Assets/img/Automation Page/googleSlides.png")
    },
    {
    id: 11,
    name: 'PayPal',
    phoneNumber: '',
    image: require("../../Assets/img/Automation Page/paypal.png")
    },
    {
    id: 12,
    name: 'Gmail',
    phoneNumber: '',
    image: require("../../Assets/img/Automation Page/gmail.png")
    },
    {
    id: 13,
    name: 'Trello',
    phoneNumber: '',
    image: require("../../Assets/img/Automation Page/trello.png")
    },
    {
    id: 14,
    name: 'Google Drive',
    phoneNumber: '',
    image: require("../../Assets/img/Automation Page/googleDrive.png")
    },
    {
    id: 15,
    name: 'QuickBooks',
    phoneNumber: '',
    image: require("../../Assets/img/Automation Page/quickbooks.png")
    }
  ]
  
class AutomationApps extends Component {

  constructor(props) {
    super(props)
    this.state = {
      displayedApps: APPS,
    }
  
  }

  getInitialState () {
    return {
      displayedApps: APPS      
    };
  }

  searchHandler (event) {
    let searcjQery = event.target.value.toLowerCase(),
        displayedApps = APPS.filter((el) => {
          let searchValue = el.name.toLowerCase();
          return searchValue.indexOf(searcjQery) !== -1;
        })
    this.setState({
      displayedApps: displayedApps
    })
  }

  render () {
    let apps = this.state.displayedApps;
    return (
      <div className=" jumbotron jumbotron-fluid min-vh-auto m-0 ">
        <div className="container container-fluid p-5 text-center">
          <p className="lead text-center p-3">Our Geeks connect many web apps, and frequently add new apps. They integrate with apps such as Slack, Quickbooks, Google Sheets, Google Docs, & many more!</p>
          <div style={{backgroundColor:"white"}}>
            <div class="inputContainer">
              <i class="fas fa-search icon"> </i>
              <input type="text" placeholder="Search for apps you use..." className="Field lead text-center"  onChange={this.searchHandler.bind(this)}/>
            </div>
            <nav>
              <ul className='navUl navUls uul'>
                {
                  apps.map((el) => {
                    return <Contact key={el.id}
                                    name={el.name}
                                    image={el.image}
                                    phone={el.phoneNumber} 
                          /> 
                  })
                }          
              </ul>
            </nav>
          </div>
        </div>
      </div>     
    )
  }
};


const AutomationApp = () => {

  return (
    <AutomationApps />
   
  );
};
export default withRouter(AutomationApp);
