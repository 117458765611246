import React from "react";
//
const CloudIT = () => {
  return (
    <div>
      <p className="lead text-center">Let’s get you on the web! NeedForGeeks can introduce, guide and transform your business to a cloud powerhouse! We provide comprehensive support for:</p>
      <div className='row p-3'>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Blogs And Social Media Management</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Site Design And Construction</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Mailing Lists & CRM Software</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Cloud Backup Solutions</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Cloud Sync Solutions</li>
          </ul>
        </div>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">  
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>WordPress Development</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Cloud Music Management</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Cloud Photo Management</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>iCloud, Google And Dropbox Expertise</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CloudIT;
