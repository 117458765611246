import React from "react";

const RemoteSupport = () => {
  return (
    <div>
      <p className="text-center lead">We can provide support and consultations through phone or remote desktop for the following services and beyond:</p>
      <div className='row p-3'>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Virus And Malware Removal</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Spyware And Privacy Protection</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Basic Program Tutoring</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>PC Purchases And Upgrades</li>
          </ul>
        </div>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>  
          <ul className="fa-ul">  
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>PC & Mac Repair & Upgrades</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>E-Mail Solutions</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Social Media Management</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Computer Tune-Ups</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RemoteSupport;
