import React from "react";
import AOS from 'aos';

AOS.init();
const WebsiteSkills = () => {
    return (
      <div>
        <div className="page-content">
          <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="skill" style={{overflowX:"hidden", }}>
            <div className="container">
              <h5 className="display-4 mb-5 text-center">Web Services</h5>
              
              <div className="card-deck" data-aos="fade-right" style={{display: "flex", justifyContent: "center", paddingLeft:"20px", paddingRight:"20px"}}>
                <div className="row">
                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/Website-Design.jpg')} alt=""/>  
                    <div className="card-body">
                      <h5 className="card-title">Web Design</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                      <li className="list-group-item">A professional, attractive, and easy to use website is essential in today's economy. Whether you need a completely new site or just a redesign of your existing site, we are here to help. Our Geeks provide custom website solutions that deliver a compelling user experience while integrating with your overall marketing goals and brand identity.</li>
                      </ul>
                    </div>
                  </div>

                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/web-development.jpg')} alt=""/> 
                    <div className="card-body ">
                      <h5 className="card-title">Web Development</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">When it is about leveraging elegant, scalable web application development solutions, hire only a reliable website development partner that understands a business as well as a business owner. We are a dependable web application company that offers the most intuitive web applications that put power in the hands of enterprises to manage resources and perform better.</li>
                        </ul>
                    </div>
                  </div>

                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/SEO.png')} alt=""/> 
                    <div className="card-body">
                      <h5 className="card-title">Search Engine Optimization (SEO)</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">Imagine that you have your own business and web address, but no one knows about it. What should you do? A website requires unique advertising to optimize promotion, growing sales, and online business. In this internet era, search engine optimization plays a vital role in using your website to gain more and more clients and boost business.</li>
                      </ul>                  
                    </div>
                  </div>
                </div>              
              </div>

              <div className="card-deck" data-aos="fade-right" style={{display: "flex", justifyContent: "center", paddingLeft:"20px", paddingRight:"20px"}}>
                <div className="row">
                <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/web-hosting.jpg')} alt=""/> 
                    <div className="card-body ">
                      <h5 className="card-title">Web Hosting</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">Every Website Needs to be hosted. Contact Us today and we will find the right hosting plan for you!</li>
                      </ul>
                    </div>
                  </div>
                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/Website-Maintenance.jpg')} alt=""/>  
                    <div className="card-body">
                      <h5 className="card-title">Website Maintenance and Update</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">Our website maintenance and support services are a cost effective way to keep your site up to date. Your website needs to evolve as your business evolves. Our monthly maintenance services are designed to keep your site updated, working properly, and optimized for the search engines. The monthly maintenance support services include text and graphics changes, link updates, content and image updates, page updates, search engine submission and optimization, and any other site related needs.</li>
                      </ul>
                    </div>
                  </div>

                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/ORM.jpg')} alt=""/> 
                    <div className="card-body ">
                      <h5 className="card-title">Online Reputation Management (ORM)</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">If your company is focused on providing great customer service and engaging with your fans online (and you should be!) Google Alerts can be a good way to track when your customers mention you so you can reach out personally to thank them for telling their friends about you. On average, a customer tells nine people about their experience with a company, meaning your customers are talking about you, and you should know what they’re saying. There’s no faster way to earn lifelong customers than going the extra mile by sending a personalized thank you note for their loyalty, and Google Alerts makes it easier than ever to do that.</li>
                      </ul>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default WebsiteSkills;
