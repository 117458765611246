import React from "react";

const BusinessIT = () => {
  return (
    <div>
      <p className="lead text-center">Being a small business is hard (we know!) and the last thing you need is your computers failing at the wrong time. We’ve got your back digitally as well as financially and always with a smile.</p>
      <div className='row p-3'>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Virus And Malware Removal</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Computer Purchase And Upgrades</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Server Installations, Maintenance & Upgrades</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>PC & Mac Repair & Upgrades</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Wireless Networks</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Point-Of-Sale (POS) Support & Setup</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Desk Space And Wire Reorganization</li>
          </ul>
        </div>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Surveillance Camera Systems</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Printers And Scanners</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Networking Equipment & Security</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>E-Mail Solutions</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Data Backup & Recovery</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>VOIP Solutions</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BusinessIT;
