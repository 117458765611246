import React from "react";
import { Chat, Phone, Email } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import Configs from "../../Assets/editable-stuff/configurations.json";

const CLOUDMIGRATION = () => {
  return (
    <div id="CLOUDMIGRATION" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px", background:"white"}}>
      <div className="container container-fluid p-5">
        <div className="row">
          <img
            className="border-secondary rounded-circle imageCss"
            src={require("../../Assets/img/IT Page/cloudLogo.png")}
            alt="..."
          />
          <div className={`col-lg`}style={{marginBottom:"-50px"}}>
            <h1 className="display-4 mb-3 text-center">{'Cloud Migration'}</h1>
            <p className="lead text-center">{'Everyone knows the future is in the cloud, but how do you get there? We can reliably and professionally float your files, e-mails, contacts, calendars and many other systems over to remote servers so you never have to worry about upgrading or backing up again.'}
           <br/>
           <br/>
           <p className="lead text-center"> Call, Text or Email us today!</p>
           <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
                href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.cloudTemplate}>Text</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
                href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.cloudSubject + "&body=" + Configs.cloudTemplate}>Email</Button>
           </p>
          </div>
          {
            <div className = "noImageCss">
            <div className="display-4 mb-5 text-center d-lg-block align-self-center">
              <img
                className="border-secondary rounded-circle"
                src={require("../../Assets/img/IT Page/cloudLogo.png")}
                alt="..."
                style={{maxWidth: "256px", maxHeight: "256px"}}
              />
               </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CLOUDMIGRATION;
