import React from "react";
import AOS from 'aos';

AOS.init();
const AppSkills = () => {
    return (
      <div>
        <div className="page-content">
          <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="skill" style={{overflowX:"hidden", }}>
            <div className="container">
              <h5 className="display-4 mb-5 text-center">App Services</h5>
              
              <div className="card-deck" data-aos="fade-right" style={{display: "flex", justifyContent: "center", paddingLeft:"20px", paddingRight:"20px"}}>
                <div className="row">
                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/mobile-app-design.png')} alt=""/>  
                    <div className="card-body">
                      <h5 className="card-title">App Design</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                      <li className="list-group-item">The difference between good app design and a poor app design is usually the quality of its user experience. Fast loading times, ease of use, and overall customer satisfaction during an interaction should be integral parts of your design. A great app design is clearly laid out, efficient to use, and aesthetically pleasing. App design combines the user interface (UI) and user experience (UX). While UI lends itself to the overall style of the app (including the colors, fonts, and general look and feel), UX focuses on the actual functionality and usability.</li>
                      </ul>
                    </div>
                  </div>

                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/app-development.jpg')} alt=""/> 
                    <div className="card-body ">
                      <h5 className="card-title">App Development</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">Our Geeks develop mobile apps that combine great design, top-notch technology, and understanding of business values. We're with you all the way - from design to shipment to make sure that every mobile solution we deliver meets your expectations. With many years of experience in software engineering and mobile development, our expert developers know how to quickly build a perfect app, including great user experience at the front-end and reliable and scalable back-end solutions.</li>
                        </ul>
                    </div>
                  </div>
                  <div className="card">
                    <img src={require('../../Assets/img/Web and App Page/app-maintenance.jpg')} alt=""/>  
                    <div className="card-body">
                      <h5 className="card-title">App Maintenance and Update</h5>
                      <ul className="list-group list-group-flush" data-aos="fade-down">
                        <li className="list-group-item">Mobile technologies advance much faster with new versions of mobile platforms. Furthermore, new mobile devices are released every few months. With platforms and devices rapidly changing, maintaining and updating your application is essential for keeping mobile apps efficient for users. Our Geeks continue ensuring your success even after the product launch by providing ongoing analysis and enhancements.</li>
                      </ul>
                    </div>
                  </div>
                 
                </div>              
              </div>

             
        </div>
        </div>
        </div>
      </div>
    );
  }
export default AppSkills;
