import React, { Fragment, Component } from "react";
import {Route, Switch, Redirect, BrowserRouter as Router} from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './Assets/css/Navigation.css';
import './Assets/css/main.css';
import './Assets/css/IT.css';
import './Assets/css/experience.css';
import './Assets/css/Contact.css';
import './Assets/css/bootstrap.min.css';
import './Assets/css/AutomationCSS.scss';
import './Assets/css/aos.css';
import './Assets/css/aboutStyleCSS.css';
import './Assets/css/App.css';


// Components
import MainHeader from "./components/Main Page/MainHeader";
import AboutMe from "./components/Main Page/AboutMe";
import ContactForm from "./components/Main Page/contact";
import ScrollTop from "./components/ScrollTop";

// Footer
import Footer from "./components/Footer/Footer";
import DeveloperHeader from "./components/Footer/BecomeDeveloperHeader";
import Developer from "./components/Footer/BecomeADeveloper";
import PayHeader from "./components/Footer/HowToPayHeader";
import Pay from "./components/Footer/HowToPay";
import TermsHeader from "./components/Footer/TermsHeader";
import Terms from "./components/Footer/Terms";

// Pages
import SubmitPage from "./pages/submit"
import ComingSoonPage from "./pages/comingsoon";
import NotFoundPage from "./pages/404";

// IT
import ITHeader from "./components/IT Page/ITHeader";
import WIFIOPTIMIZATION from "./components/IT Page/WIFIOPTIMIZATION";
import SYSTEMUPGRADES from "./components/IT Page/SYSTEMUPGRADES";
import VirusRemoval from "./components/IT Page/VirusRemoval";
import CLOUDMIGRATION from "./components/IT Page/CLOUDMIGRATION";
import ITTabs from "./components/IT Page/ITTabs";
import PageNavigation from "./components/Navigation/PageNavigation";

// WA
import WAHeader from "./components/Web&App/WAHeader";
import AboutApp from "./components/Web&App/AboutApp";
import AboutWebsite from "./components/Web&App/AboutWebsite";
import WebsiteSkills from "./components/Web&App/WebsiteSkills";
import AppSkills from "./components/Web&App/AppSkills";

// Automation
import AutomationHeader from "./components/Automation/AutomationHeader";
import AboutAutomation from "./components/Automation/AboutAutomation";
import ConnectingApps from "./components/Automation/ConnectingApps";
import AutomationApps from "./components/Automation/AutomationApps";
import WaveGoodBye from "./components/Automation/WaveGoodBye";

const Main = () =>  (
  <Fragment>
    <MainHeader />
    <AboutMe />
    <ContactForm />
  </Fragment>
);

const IT = () => (
  <Fragment>
    <ITHeader/>
    <VirusRemoval/>
    <WIFIOPTIMIZATION/>
    <SYSTEMUPGRADES/>
    <CLOUDMIGRATION/>
    <ITTabs/> 
  </Fragment>
  );

const WebAndApp = () => (
  <Fragment>
    <WAHeader/>
    <AboutWebsite/>
    <WebsiteSkills/>
    <AboutApp />
    <AppSkills />
  </Fragment>
);

const AutomationPage = () => (
  <Fragment>
    <AutomationHeader />
    <AboutAutomation />
    <ConnectingApps />
    <AutomationApps />
    <WaveGoodBye />
  </Fragment>
);

const HowToPay = () =>  (
  <Fragment>
    <PayHeader />
    <Pay />
  </Fragment>
);

const BecomeADeveloper = () =>  (
  <Fragment>
    <DeveloperHeader />
    <Developer />
  </Fragment>
);

const TermsAndConditions = () =>  (
  <Fragment>
    <TermsHeader />
    <Terms />
  </Fragment>
);

const Submit = () =>  (
  <Fragment>
    <SubmitPage />
  </Fragment>
);

const ComingSoon = () =>  (
  <Fragment>
    <ComingSoonPage />
  </Fragment>
);

const NotFound = () =>  (
  <Fragment>
    <NotFoundPage />
  </Fragment>
);

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollTop />
        <PageNavigation/>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/submit" component={Submit} />
          <Route exact path="/comingsoon" component={ComingSoon} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/IT" component={IT} />
          <Route exact path="/Web-App" component={WebAndApp} />
          <Route exact path="/How-To-Pay" component={HowToPay} />
          <Route exact path="/Automation" component={AutomationPage} /> 
          <Route exact path="/Terms-And-Conditions" component={TermsAndConditions} />
          <Route exact path="/Become-a-Developer" component={BecomeADeveloper} />
          <Route exact path="/Referrals-Affiliates" component={ComingSoon} />
          <Redirect to="/404"/>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
