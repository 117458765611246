import React, { useState} from "react";
import Configs from "../../Assets/editable-stuff/configurations.json";
import { Button } from "@material-ui/core";
import { Chat, Phone, Email } from "@material-ui/icons";

const AboutApp = () => {
  const [heading] = useState("Apps");
  const [aboutApp] = useState(Configs.aboutApp);

  return (
    <div id="aboutApp" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px",backgroundColor:"white"}}>
      <div className="container container-fluid p-5">
        <div className="row">
        <div className={`col-lg text-center`}>
              <img
                className=""
                src={require('../../Assets/img/Web and App Page/mobile-app.png')} 
                alt="profilepicture"
              />
          
            <h1 className="display-4 mb-3 text-center">{heading}</h1>
            <p className="lead text-center">{aboutApp}</p> 
            <br />
            <p className="lead text-center"> Call, Text or Email us today!</p>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
                href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.appTemplate}>Text</Button>
              <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
                href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.appSubject + "&body=" + Configs.appTemplate}>Email</Button>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutApp;
