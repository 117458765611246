import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Configs from "../../Assets/editable-stuff/configurations.json";
import { scroller} from 'react-scroll';
import "../../App";
import { Helmet } from 'react-helmet';


const WAHeader = () => {

  const scrollFunc=(e)=>{
  
    scroller.scrollTo(e, {
      duration: 1400,
      delay: 0,
      offset: 25,
      smooth: 'easeInOutQuart'
    })
  }

  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };

  return (
    <div>
      <Helmet>‍
        <title>Automation Services</title>
      </Helmet>
      <div
        id="AutomationHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light  d-flex align-content-center align-items-center "
        style={{marginBottom:0,height:"100vh"}}
      >
        <div style={{marginTop:"25px"}} className="container container-fluid text-center">
          <h1 className="header-text-2">{'Automation'}</h1>
          
          <Typist className="lead"> {'| Easy automation for busy people. Our Geeks move info between your web apps automatically, so you can focus on your most important work.'}</Typist>
          <div style={{paddingTop:"50px"}}></div>
          <Link className="btn btn-outline-light" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light" role="button" to="/IT">Information Technology</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light" role="button" to="/Web-App">Websites & Apps</Link>
          <div className="p-6" style={{paddingTop:"40px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <br/><br/><br/>
          <IconButton onClick={()=>scrollFunc("aboutAutomation")} style={{backgroundColor:"#d1eef8", position:"absolute",bottom: "10px",left:"50%", marginLeft:"-30px"}}>
            <ExpandMoreOutlinedIcon style={{}} fontSize="large" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default WAHeader;
