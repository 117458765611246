import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Home, Business, FilterDrama, SettingsRemote} from "@material-ui/icons";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import HomeIt from "./HomeIT";
import BusinessIT from "./BusinessIT";
import CloudIT from './CloudIT';
import RemoteSupport from './RemoteSupport';
function TabPanel(props) {
  const {children, value, index, ...other } = props;

  return (
  
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>

    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
   
  },
}));

export default function ScrollableTabsButtonPrevent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <div id="ITTABS" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
      <div className="container container-fluid p-5">
        <div className={classes.root}>
        <h1 className="display-4 mb-3 text-center">{'Services'}</h1>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="off"
              aria-label="scrollable prevent tabs example"
            >
              <Tab style={{minHeight: "150px", fontSize: "25px"}} label="HOME" icon={<Home />} aria-label="phone" {...a11yProps(0)} />
              <Tab style={{minHeight: "150px", fontSize: "25px"}}  label="BUSINESS" icon={<Business />} aria-label="favorite" {...a11yProps(1)} />
            </Tabs> 
          </AppBar>
    
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="off"
            
              aria-label="scrollable prevent tabs example"
            >
              <Tab style={{display:"none"}} {...a11yProps(0)}/>
              <Tab style={{display:"none"}} {...a11yProps(1)}/>
              <Tab style={{minHeight: "150px", fontSize: "25px"}} label="CLOUD & WEB" icon={<FilterDrama />} aria-label="person" {...a11yProps(2)} />
              <Tab style={{minHeight: "150px", fontSize: "25px"}}  label="REMOTE SUPPORT" icon={<SettingsRemote />} aria-label="help" {...a11yProps(3)} />
            </Tabs>
          </AppBar>

          <TabPanel style={{background:"white"}} value={value} index={0}>
            <HomeIt/>
          </TabPanel>
          <TabPanel style={{background:"white"}} value={value} index={1}>
           <BusinessIT/>
          </TabPanel>
          <TabPanel style={{background:"white"}} value={value} index={2}>
           <CloudIT/>
          </TabPanel>
          <TabPanel style={{background:"white"}} value={value} index={3}>
            <RemoteSupport/>
          </TabPanel>
          
        </div>
      </div>
    </div>
  );
}
