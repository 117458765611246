// const firestoreService = require("firestore-export-import");
// const serviceAccount = require("./serviceAccountKey.json");

// const databaseURL = "https://website-a7b01.firebaseio.com"

// firestoreService.initializeApp(serviceAccount, databaseURL);

// firestoreService.restore("data.json");

import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDJr0ycWR3AbScnk2EsR6W9vVa0wBnhGx4",
    authDomain: "invitations-51f4f.firebaseapp.com",
    projectId: "invitations-51f4f",
    storageBucket: "invitations-51f4f.appspot.com",
    messagingSenderId: "96667588568",
    appId: "1:96667588568:web:0b67cbcbf32855e1ac84ad",
    measurementId: "G-K5Z0WS4V3Z"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;