import React from "react";
import Configs from "../../Assets/editable-stuff/configurations.json";
import { Button } from "@material-ui/core";
import { Chat, Phone, Email } from "@material-ui/icons";

const WaveGoodBye = () => {
  return (
    <div id="aboutAutomation" className="jumbotron jumbotron-fluid bg-white min-vh-auto m-0" style={{paddingTop:"80px"}}>
      <div className="container container-fluid p-5" style={{textAlign:"center"}}>
        
            <h1 className="display-4 mb-4 text-center" style={{fontSize: "2.8rem"}}><b>Wave Goodbye to your time consuming work</b></h1>
            <p className="lead text-center">Don't waste another minute. Contact us now to automate your busywork.</p>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + Configs.PhoneNumber}>Call</Button>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />} 
              href={"sms: " + Configs.PhoneNumber + "&body=" + Configs.automationTemplate}>Text</Button>
            <Button style={{margin: "3px", borderColor:"#173962", color:"#173962"}} variant="outlined" color="primary" size="large" type="submit" startIcon={<Email />} 
              href={"mailto: " + Configs.EmailAddress + "?subject=" + Configs.automationSubject + "&body=" + Configs.automationTemplate}>Email</Button>
          </div>
        </div>
      
  );
};

export default WaveGoodBye;
