import React from "react";

const HomeIT = () => {
  return (
    <div>
      <p className="lead text-center">Your home is your sanctuary, and sanctuaries should be problem-free. We’ll optimize computers, audio/video equipment and wireless networks and even teach you how to troubleshoot things yourself if needed.</p>
      <div className='row p-3'>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Spyware And Privacy Protection</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Virus And Malware Removal</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>PC & Mac Repair & Upgrades</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Data Backup & Recovery</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Computer Tune-Ups</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Wireless Networks</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Printers And Scanners</li>
          </ul>
        </div>
        <div className='col-lg' style={{justifyContent:"center", display:"flex"}}>
          <ul className="fa-ul">
            <li><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Noise & Loudness Issues</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Desk Space And Wire Reorganization</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Phone And Tablet Training</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Gaming Rigs</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Smart Home Appliances</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Home Theater Systems</li>
            <li style={{paddingTop: "10px"}}><span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>Photos & Music Database Management</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeIT;
