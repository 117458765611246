import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import Configs from "../Assets/editable-stuff/configurations.json";
import "../App";

const ComingSoonPage = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div>
      <div
        id="comingsoon"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="header-text-2 footer-padding-top">Under Construction...</h1>
          <Typist cursor={{ hideWhenDone: true }}>
            <span className="lead">Welcome to our site! We are currently working on this site every minute of an hour.</span>
            <div className="lead">
              <p>Check out and follow our social media sites.</p>
            </div>
          </Typist>
          <div className="p-6" style={{paddingTop:"40px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <div>
                {/* <form autoCorrect="off" onSubmit={e => handleSubmit(e)} id="subscribe-form"
              style={{backgroundColor: "white", flexDirection: "column", padding: "15px", borderRadius: "10px", maxWidth:"650px", display:"inline-block", width:"100%",}}
            >
              <div className="form-row">
                <div className="form-group col-md-8">
                  <TextField required label="Email Address" type="email" id="subscribe" margin="normal" variant="outlined" placeholder="Email Address" style={{height: "56px", paddingTop: 0, width: "100%", }}/>
                </div>
                <div className="form-group col-md-2" style={{paddingTop: "13px",}}>
                  <Button variant="contained" color="primary" size="large" type="submit" className={classes.button} endIcon={<SubscriptionsRounded />}> Subscribe!</Button> 
                </div>
              </div>             
            </form> */}
          </div>
          <Link className="btn btn-outline-light" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light" role="button" to="/IT">Information Technology</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light" role="button" to="/Web-App">Websites & Apps</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light" role="button" to="/Automation">Automation</Link>
        </div>
      </div>
    </div>
  );
};
export default ComingSoonPage;